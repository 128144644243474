<template>
  <div>
    <!-- 调查居民 -->
    <div class="resident-btns">
      <div class="title">
        <span class="blue"></span>
        <p>居民信息</p>
      </div>
      <div class="btns">
        <el-button class="btns-item" type="primary" @click="addResidentRow"
          >新增人员</el-button
        >
        <el-upload
          class="btns-item upload-item"
          :action="upload"
          accept=".xls, .xlsx"
          :show-file-list="false"
          :headers="myHeaders"
          :on-success="handelSuccess"
        >
          <el-button type="primary" style="width: 120px">导入</el-button>
        </el-upload>

        <el-button
        :disabled="selectResidents.length==0"
          type="danger"
          style="width: 120px"
          @click="deleteResidentRow()"
          >删除</el-button
        >

        <el-button
          type="primary"
          style="width: 120px"
          @click="downloadTemplate()"
          >下载导入模板</el-button
        >
      </div>
    </div>
    <el-form
      :model="userListForm"
      :rules="userListFormRules"
      ref="userListFormRef"
    >
      <el-table
        :is-show-select="true"
        :data="userListForm.userList"
        table-size="medium"
        :border="true"
        @selection-change="handleSelectionChange"
        :row-class-name="rowClassName"
      >

      <!-- <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="text" size="small"
              @click="deleteResidentRow(scope.$index)">删除</el-button>
          </template>
        </el-table-column> -->



        <el-table-column
          type="selection"
          width="55"
          :selectable="selectable"
        ></el-table-column>

        <el-table-column label="姓名" width="120">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">姓名</span>
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="`userList.${scope.$index}.name`"
              :rules="userListFormRules.name"
            >
              <el-input
                v-model.trim="scope.row.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="性别" width="100">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">性别</span>
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="`userList.${scope.$index}.sex`"
              :rules="userListFormRules.sex"
            >
              <el-select
                class="select-width"
                v-model.trim="scope.row.sex"
                clearable
                placeholder="请选择性别"
              >
                <el-option
                  v-for="item in sexSelect"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="证件类型" width="150">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">证件类型</span>
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="`userList.${scope.$index}.cardType`"
              :rules="userListFormRules.cardType"
            >
              <el-select
                v-model.trim="scope.row.cardType"
                placeholder="证件类型"
                class="select-width"
                @change="cardTypeChang(scope.row)"
              >
                <el-option
                  v-for="item in typeCertificate"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="cardNo" label="身份证号码" width="220">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">证件号码</span>
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="`userList.${scope.$index}.cardNo`"
              :rules="userListFormRules.cardNo"
            >
              <el-input
                class="select-width"
                v-model.trim="scope.row.cardNo"
                placeholder="请输入证件号"
                @blur="idCodeCount(scope.row)"
              />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="linkPhone" label="联系电话" width="180">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">联系电话</span>
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="`userList.${scope.$index}.linkPhone`"
              :rules="userListFormRules.linkPhone"
            >
              <el-input
                class="select-width"
                maxlength="11"
                v-model.trim.number="scope.row.linkPhone"
                placeholder="请输入联系电话"
              />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="年龄" width="100">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">年龄</span>
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="`userList.${scope.$index}.age`"
              :rules="userListFormRules.age"
            >
              <el-input
                v-model.trim="scope.row.age"
                placeholder="请输入年龄"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="国籍" width="150">
          <template slot-scope="scope">
            <el-form-item :prop="`userList.${scope.$index}.country`">
              <el-select
                filterable
                placeholder="请选择国籍"
                class="select-width"
                v-model.trim="scope.row.country"
              >
                <el-option
                  v-for="item in nationalityselet"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">
                    {{ item.value }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="民族" width="120">
          <template slot-scope="scope">
            <el-form-item :prop="`userList.${scope.$index}.nation`">
              <el-select
                filterable
                placeholder="请选择民族"
                class="select-width"
                v-model.trim="scope.row.nation"
              >
                <el-option
                  v-for="item in nationSelect"
                  :key="item.dictLabel"
                  :label="item.dictLabel"
                  :value="item.dictLabel"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">
                    {{ item.value }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="现住地" width="320">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">现住地</span>
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="`userList.${scope.$index}.currentAddressCode`"
              :rules="userListFormRules.currentAddressCode"
            >
            <el-cascader
                :ref="`currentAddressRef${scope.$index}`"
                class="select-width"
                v-model="scope.row.currentAddressCode"
                :props="arearProps"
                clearable
                @change="(e) => handelCurrentAddress(e, scope.row, scope.$index)"
              >
              </el-cascader>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="详细地址" width="200">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">详细地址</span>
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="`userList.${scope.$index}.currentAddressDetail`"
              :rules="userListFormRules.currentAddressDetail"
            >
              <el-input
                v-model.trim="scope.row.currentAddressDetail"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="调查对象所属地区" width="240">
          <template slot="header">
            <span style="color: #f56c6c" v-if="currentSurveyResult == 1">*</span>
            <span style="margin-left: 6px">调查对象所属地区</span>
          </template>
          <template slot-scope="scope">
           
            <el-form-item
              :prop="`userList.${scope.$index}.surveyObjectBelongAreaCode`"
              :rules="userListFormRules.surveyObjectBelongAreaCode"
            >
              <el-cascader
                class="select-width"
                ref="surveyObjectBelongAreaRef"
                v-model.trim="scope.row.surveyObjectBelongAreaCode"
                :props="props"
                @change="(e) => handelSurveyObjectBelongArea(e, scope.row)"
                clearable
              ></el-cascader>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="是否医务人员" width="120">

          <template slot-scope="scope">
            <el-form-item
              :prop="`userList.${scope.$index}.medicalStaff`"
              :rules="userListFormRules.medicalStaff"
            >
              <el-select
                class="select-width"
                v-model.trim="scope.row.medicalStaff"
                clearable
                placeholder=""
              >
                <el-option
                  v-for="item in threeSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="是否临床症状" width="120">

<template slot-scope="scope">
  <el-form-item
    :prop="`userList.${scope.$index}.clinicalSymptoms`"
    :rules="userListFormRules.clinicalSymptoms"
  >
    <el-select
      class="select-width"
      v-model.trim="scope.row.clinicalSymptoms"
      clearable
      placeholder=""
    >
      <el-option
        v-for="item in threeSelect"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>
        </el-table-column>

        <el-table-column label="新冠疫苗接种情况" width="140">

        <template slot-scope="scope">
          <el-form-item
            :prop="`userList.${scope.$index}.vaccination`"
            :rules="userListFormRules.vaccination"
          >
            <el-select
              class="select-width"
              v-model.trim="scope.row.vaccination"
              clearable
              placeholder=""
            >
              <el-option
                v-for="item in threeSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        </el-table-column>

        <el-table-column label="是否有基础疾病" width="140">

        <template slot-scope="scope">
          <el-form-item
            :prop="`userList.${scope.$index}.basicDiseases`"
            :rules="userListFormRules.basicDiseases"
          >
            <el-select
              class="select-width"
              v-model.trim="scope.row.basicDiseases"
              clearable
              placeholder=""
            >
              <el-option
                v-for="item in threeSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        </el-table-column>

      </el-table>
    </el-form>
  </div>
  
</template>

<script>
import { http } from "@/api/index";
import { mapState } from "vuex";
import { exportTemplateKeyIn } from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import DialogInfo from "@/components/DialogInfo";
import { isIDCard, isPhone } from "@/utils/validate";
import { SURVEY_STATUS, SURVEY_RESULT ,THREE_SELECT} from "@/common/constants";
import constants from "@/common/constants";
import { getIdNumberInfo, importUrlFile } from "@/utils/utils";
export default {
  name: "ResidentBasicInfo",
  props: {
    // //新增或者修改类型
    // type: {
    //   type: String,
    //   default: "",
    // },
    // //流调人员组
    // surveyGroupUserListSelect: {
    //   type: Array,
    //   default: function () {
    //     return [];
    //   },
    // },
    // //涉事场所
    // userPlaceSelect: {
    //   type: Array,
    //   default: function () {
    //     return [];
    //   },
    // },
    // //任务状态
    // preTaskStatus: {
    //   type: String,
    //   default: "",
    // },
    // //上传文件请求头
    myHeaders: {
      type: Object,
      default: function () {
        return {};
      },
    },
    surveyResult:{
      type:String,
      default:''
    }
  },
  components: { DialogInfo },
  watch: {
    surveyResult(v) {
      this.currentSurveyResult = v;
      this.$forceUpdate()
      this.userListFormRules.surveyObjectBelongAreaCode = [
        { required: v == 1, message: '请选择调查对象所属地区', trigger: 'blur'}
      ]
      // console.log('watch', this.surveyResult )
      // console.log(this.userListFormRules, 'userListFormRules')
    }
  },
  data() {
    const phoneCheck = (rule, value, callback) => {
      if (value && !isPhone(value)) {
        this.$message({
          message: "关请输入正确的手机号码",
          type: "error",
          duration: "1000",
        });
        callback(new Error("请输入正确格式的手机号"));
      } else {
        callback();
      }
    };
    
   
    return {
      currentSurveyResult: this.surveyResult,
      sexSelect: [
        { id: "0", name: "女" },
        { id: "1", name: "男" },
      ],
      threeSelect:THREE_SELECT,
      upload: "/ohealth/api/v1/epidemicControl/epidemiologicalsurvey/uploadSurveyObjectExcel", //居民人员上传地址
      setDialogVisible: false, //设置对话框
      setDialogTitle: "", //设置对话框标题
      setDialogType: "", //设置流调人员为1，设置涉事场所为2
      selectResidents: [], //选择的居民信息
      surveyUserName: "", //设置的流调人员姓名
      surveyUserId: "", //设置的流调人员id
      surveyGroupId: "", //设置的流调组id
      placeList: [], //设置的涉事场所
      placeIds: [], //设置的涉事场所ids,用于显示
      nationSelect:JSON.parse(localStorage.getItem("nationSelect")),
      nationalityselet: JSON.parse(localStorage.getItem("nationalityselet")), //国籍选项
      typeCertificate: constants.TYPE_CERTIFICATE.filter((item, i) => i !== 0), //身份证类型
      userListForm: {
        userList: [], //居民信息数组 单个
      },
      userListFormRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        // cardNo: [
        //   { required: true, message: "请输入证件号码", trigger: "blur" },
        //   { validator: cardNoCheck, trigger: "blur" },
        // ],
        cardNo: [
          { required: true, message: "请输入证件号码", trigger: "blur" }
        ],
        linkPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { validator: phoneCheck, trigger: "blur" },
        ],
        cardType: [{ required: true, trigger: "change" }],
        sex: [{ required: true, trigger: "change" }],
        age: [{ required: true, trigger: "blur" }],
        currentAddressCode: [
          { required: true, message: "请输入现住地", trigger: "change" },
        ],
        currentAddressDetail: [
          { required: true, message: "请输入现住地详细地址", trigger: "blur" },
        ],
        surveyObjectBelongAreaCode: [
          { required: this.surveyResult == 1, message: '请选择调查对象所属地区', trigger: 'blur'}
        ]
      },

      //3级
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          // console.log(node, '输出node，node00000')
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 10);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3,
              }));
              resolve(nodes);
            });
          }
        },
      },
      //4级
      arearProps: {
        lazy: true,
        lazyLoad(node, resolve) {
          // console.log(node,resolve,'输出node，resolve1111111111')
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 10);
          } else {
            let formm = { label: node.data.value };
            // console.log(formm,'输出formm22222222222222')
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 4,
              }));
              resolve(nodes);
            });
          }
        },
      },


    };
  },

  // computed: {
  //   ...mapState({
  //     nationSelect: (state) => state.user.nationSelect, // 民族
  //   }),
  // },
  created() {
    // console.log(this.surveyResult,'打印流调结果')
    // console.log(this.nationSelect,'nationSelect')
  },
  mounted() {
    // console.log(this.surveyResult,'mounted里面')
  },

  methods: {
    // 动态新增表格行数
    rowClassName({row, rowIndex}) {
      row.xh = rowIndex + 1;
    },
    cardTypeChang(v){
      // console.log(v,'打印vvvvvvv')
      // console.log('77777')
    },
    cardNoCheck(rule, value, callback) {
      // console.log(rule)
      // console.log(value)
      // console.log(callback)
      if (value && !isIDCard(value)) {
        this.$message({
          message: "请输入正确的证件号码",
          type: "error",
          duration: "1000",
        });
        callback(new Error("请输入正确格式的身份证号"));
      } else {
        callback();
      }
    },
    //新增居民信息
    addResidentRow() {
      this.userListForm.userList.push({
        age: "",
        basicDiseases: "",
        cardNo: "",
        cardType: "1",
        clinicalSymptoms: "",
        country: "中国大陆",
        currentAddress: this.$store.state.defaultAddress.addressFourDetail,
        currentAddressCode: this.$store.state.defaultAddress.addressFourCode.split(','),
        currentAddressDetail: "",
        linkPhone: "",
        medicalStaff: "",
        name: "",
        nation: "汉族",
        sex: "",
        surveyObjectBelongArea: this.$store.state.defaultAddress.addressThreeDetail,
        surveyObjectBelongAreaCode: this.$store.state.defaultAddress.addressThreeCode.split(','),
        vaccination: "",
        _id: new Date().getTime(), // 新增时临时创建一个列的唯一值（传参的时候再删除）
      });
    },
    //删除居民
    deleteResidentRow(index) {
      this.userListForm.userList.splice(index, 1);
    },
    //删除居民
    deleteResidentRow(index) {
      // console.log(index,'indexxx')
      
      // this.selectable
      // 拿到选中的_id数组
      const selectedKeys = this.selectResidents.map((o) => o._id);
      console.log(this.userListForm.userList, 'this.userListForm.userList');
      // 过滤成不包含在选中数组里的值
      const newUserList = this.userListForm.userList.filter(
        (o) => !selectedKeys.includes(o._id)
      );
      console.log(newUserList, 'newUserList');
      // 重新set 列表
      this.userListForm.userList = newUserList;

      // console.log(this.selectable, "this.selectable");
      // console.log(this.selectResidents, "selectResidents");
      // this.userListForm.userList.splice(index, 1);
    },


    //导入居民文件处理数据
    handelSuccess(res) {
      // console.log(res, 'res')
      if (res.code == "200") {
        const loadResidents = res.data.map((o, i) => ({
          ...o,
          _id: new Date().getTime() + i,
          currentAddressCode: o.currentAddressCode ? o.currentAddressCode.split(',') : [],
          surveyObjectBelongAreaCode: o.surveyObjectBelongAreaCode? o.surveyObjectBelongAreaCode.split(',') : []
        }));
         
          this.userListForm.userList = this.userListForm.userList.concat(loadResidents);
      } else {
        this.$message.error(res.msg);
      }
    },
    //选择人群分类
    handelCrowdType(item, row) {
      row.crowdTypeId = item.id;
      row.crowdTypeCode = item.code;
      row.crowdTypeName = item.ruleName;
    },
    //选择涉事场所
    handelPlaceChange(value, row) {
      row.placeList = value.map((item) => {
        return {
          placeInvolvedId: item.id,
          placeName: item.placeName,
        };
      });
    },
    //选择调查人员
    handleSurveyUserName(row, group, user) {
      // console.log("group", group, user);
      row.surveyGroupId = group.id;
      row.surveyUserId = user.userId;
      row.surveyUserName = user.userName;
    },

    // 身份证计算
    idCodeCount(row) {
      // console.log(row, "打印rowwww");
      if (row.cardNo && row.cardNo.length == 18) {
        let obj = getIdNumberInfo(row.cardNo);
        row.sex = obj.sex == 0 ? "1" : "0";
        row.age = obj.age;
        // console.log(row.age,'11111')
      }
    },

    //现住地change
    handelCurrentAddress(value, row, index) {
      // console.log("选择完成时触发", value);
      // console.log(row, 'row')
      // console.log(value, 'value是11111111111111111111111111111区域编码');
      let node = this.$refs[`currentAddressRef${index}`].getCheckedNodes()[0];
      // console.log(node, 'node')
      if (node) {
        let currentAddress = node.pathLabels.toString();
        row.currentAddress = currentAddress; //改变后那一行的文字地址赋值
        row.currentAddressCode = value; //现住地数组赋值，传参时要转为 字符串
        // console.log(
        //   row.currentAddressCode,
        //   "打印出codecodecode"
        // );
      }
    },
    //选择所属地区
    handelSurveyObjectBelongArea(value, row) {
      // console.log(value,'111111111111111111111111111所属地区的value')
      let node = this.$refs.surveyObjectBelongAreaRef.getCheckedNodes()[0];
      if (node) {
        let surveyObjectBelongArea = node.pathLabels.toString();
        row.surveyObjectBelongArea = surveyObjectBelongArea; //三级文字的赋值
        row.surveyObjectBelongAreaCode = value; //调查对象所属地区code数组赋值，传参时要转为 字符串
      }
    },

    //关闭设置对话框
    closeSetDialog() {
      this.setDialogVisible = false;
    },
    //确定
    confirmSetDialog() {
      if (this.setDialogType == "1") {
        this.selectResidents.forEach((resident) => {
          this.userListForm.userList = this.userListForm.userList.map(
            (user) => {
              if (JSON.stringify(resident) == JSON.stringify(user)) {
                user.surveyGroupId = this.surveyGroupId;
                user.surveyUserId = this.surveyUserId;
                user.surveyUserName = this.surveyUserName;
              }
              return user;
            }
          );
        });
      } else if (this.setDialogType == "2") {
        this.selectResidents.forEach((resident) => {
          this.userListForm.userList = this.userListForm.userList.map(
            (user) => {
              if (JSON.stringify(resident) == JSON.stringify(user)) {
                user.placeList = this.placeList;
                user.placeIds = this.placeIds;
              }
              return user;
            }
          );
        });
      } else {
        //无处理
      }
      this.setDialogVisible = false;
      this.surveyGroupId = "";
      this.surveyUserId = "";
      this.surveyUserName = "";
      this.placeList = [];
      this.placeIds = [];
    },
    //设置调查人员
    setSurveyUserName(group, user) {
      this.surveyGroupId = group.id;
      this.surveyUserId = user.userId;
      this.surveyUserName = user.userName;
    },

    //选择居民
    handleSelectionChange(val) {
      console.log('打印选择的居民',val)
      this.selectResidents = val;
    },
    //居民复选框是否可点
    selectable(row, index) {
      // console.log(row,index,'打印表格的checkboxs')
      if (row.surveyStatus == 2) return false;
      return true;
    },
    //提交
    async comm() {
      if (this.userListForm.userList.length == 0) {
        this.$message({
          showClose: true,
          message: '居民信息必须添加一个',
          type: 'error'
        })
        return false
      }
      return this.$refs.userListFormRef.validate();
    },
    //详情
    residentDetail(row) {
      if (!row.epidemiologicalSurveyId) return;
      //处理数据
      row = { ...row, id: row.epidemiologicalSurveyId };
      this.$emit("showDetail", row);
    },
    //涉事场所数据处理
    handelPlaceName(placeList) {
      return placeList
        .map((item) => {
          return item.placeName;
        })
        .join(",");
    },
    // 导入下载模版
    async downloadTemplate() {
      // console.log('触发下载模板')
      try {
        let { data } = await exportTemplateKeyIn();
        // console.log("downloadTemplate -> data", data);
        // const url = window.URL.createObjectURL(
        //   new Blob([data], { type: "application/x-download" })
        // );
        // const link = document.createElement("a");
        // link.style.display = "none";
        // link.href = url;
        // link.setAttribute("download", `疾控任务管理导入模板.xlsx`);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);

        const blob = new Blob([data]);
        let url = window.URL.createObjectURL(
          new Blob([data], { type: "application/x-download" })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `疾控批量录入导入模板.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.select-width {
  width: 90%;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.resident-btns {
  display: flex;
  margin-bottom: 20px;
}

.btns {
  display: flex;
  margin-left: 30px;
}

.btns-item {
  margin-right: 10px;
  width: 120px;
}

.upload-item {
  margin-left: 10px;
  margin-right: 20px;
  background-color: #409eff;
  border-radius: 4px;
  text-align: center;
}

.el-form-item {
  margin-bottom: 0;
}

::v-deep .el-table__body {
  padding-bottom: 30px;
}
</style>